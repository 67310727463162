

































































































































































































































































































































































































































































































































































































































































































































































.el-dialog__body{
      padding: 15px;
  }
  .el-dialog__header{
      padding: 0px;
  }
  .el-dialog__footer{
      padding: 5px;
      border-top:1px solid #ccc;
  } 
  .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
  .el-table {
      th {
          padding: 0 ;
      }
      thead{
          height: 15px !important;
          color:rgb(128,128, 128);
          font-size:12px;
      }
      td {
          padding: 5px ;
          font-size:11px;
      }   
  }
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
      border-color :#3d77eb;
  }
  .el-checkbox__input.is-disabled+span.el-checkbox__label{
      color:#3d77eb;
      
  }

